<template>
    <div class="container hcptermsCondtionMainDiv">
        <div class="hcpTermConditionHeading">
            <h2>RxIx HCP Terms and Conditions </h2>
        </div>
        <p class="mt-4">
            This is a legally binding contract (hereinafter referred to as "Agreement"), by and between:
        </p>
        <p class="py-1">
            Healthcare practitioner(s) (hereinafter referred as "HCPs" or "HCP" or "You", which expression shall, unless it
            be
            repugnant to the context or meaning thereof, be deemed to mean and include his/her/their heirs, executors,
            administrators and legal representatives its successors, as the case may be), i.e. any healthcare practitioner,
            who
            accesses and/or the uses the internet resource rxix.in, including, without limitation, all its associated
            sub-domains, and/or any accompanying or associated data, applications, utilities or interface available or
            provided
            now or in future (collectively referred to as "Site") or any HCP Services (defined hereinafter) provided by
            Bamboo
            Wellness Solutions LLP, a Limited Liability Partnership registered under the Limited Liability Partnership Act,
            2008
            which expression shall unless it be repugnant to the context or meaning thereof, be deemed to mean and include,
            its
            successors and assigns) whose registered office is FG, Block 2, Jains Ashraya Phase 1, 1A Vembuliamman Koil
            Street,
            West KK Nagar, Chennai - 600078 and Head office at NSIC STP Complex, No. 403 & 404, 3rd Floor Business Park,
            B-24,
            Guindy Industrial Estate, Ekkatuthangal, Chennai, Tamil Nadu 600032</p>

        <p class="py-1">
            AND
        </p>
        <p class="py-1">
            Bamboo Wellness Solutions LLP (hereinafter
            referred to as the "Company" or "Us" or "We").
        </p>
        <p class="py-1">
            and each of which are hereinafter referred to as a "Party" and both of which are herein referred to as the
            "Parties".
        </p>
        <p class="py-1">
            Please read the terms and conditions carefully before using or accessing the Site or any material or information
            therein, or availing HCP Services (defined hereinafter) from the Company offered through the Site.
        </p>
        <p class="py-1">
            This Agreement is an electronic record in terms of the Information Technology Act, 2000 and the rules framed
            thereunder, as applicable. This electronic record is generated by a computer system and does not require any
            physical or digital signature. The agreement between, and the conduct of, the Parties shall be exclusively and
            wholly governed by this Agreement.
        </p>
        <p class="py-1">
            This Agreement is published in accordance with the provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and
            terms
            of use for access or usage of the Site.
        </p>
        <div>
            <p>1. <span class="HcpSubHeading">INTERPRETATION</span></p>
            <p class="py-2">
                1.1. The words "hereof", "hereto", "herein", and "hereunder" and words of similar import, when used in this
                Agreement, shall refer to this Agreement as a whole, and not to any particular provision of this Agreement.
            </p>
            <p class="py-2">
                1.2. Words denoting the singular shall include the plural, and words denoting any gender shall include all
                genders.
            </p>
            <p>
                1.3. The headings to clauses, sub-clauses and paragraphs of this Agreement shall only serve the purpose of
                easier
                orientation and shall not affect the contents and interpretation of this Agreement.
            </p>
            <p class="py-2">
                1.4. Any reference to "writing" shall include printing, typing, lithography, an electronic record in terms
                of
                the
                Information Technology Act, 2000 and the rules framed thereunder as applicable, and other means of
                reproducing
                words in a visible form.
            </p>
            <p class="py-2">
                1.5. The words "include" and "including" are to be construed without limitation.
            </p>
            <p class="py-2">
                1.6. "Law" means all statutes, enactments, acts of legislature, laws, ordinances, rules, by-laws,
                regulations,
                negotiations, guidelines, policies, directions, directives and orders of any Authorized Authority of or
                within
                the Republic of India.
            </p>
            <p class="py-2">
                1.7. The words "Your" or "your" or "You" or "you", when used in this Agreement, shall refer to and mean
                "HCP"
                or
                "HCPs", as the case may be.
            </p>
            <p>2. <span class="HcpSubHeading">DEFINITIONS</span></p>
            <p class="py-2">
                The following words and terms, whenever used in this Agreement, unless repugnant to the meaning or context
                thereof, shall have the respective meanings set forth below.
            </p>
            <p class="py-2">
                2.1. "Applicable Laws" or "Law" shall mean any applicable national, state or local laws (both common law and
                statute law and civil and criminal law) and all applicable subordinate legislations and regulatory codes of
                practice (including statutory instruments, guidance notes, circulars, directives, decisions, regulations,
                treaties, conventions, ordinances, order of any government agency, requirement or recommendation of any
                statute, statutory instrument, by-law or any public, governmental or statutory authority or person); all
                being of the Republic of India.
            </p>
            <p class="py-2">
                2.2. "Company" or "Us" or "We", shall mean and include Bamboo Wellness Solutions LLP, its Offices, Officers,
                Directors, Partners, Owners, Administrators, independent Contractors, Employees, Agents, or affiliates, and
                its/their successors and assigns.
            </p>
            <p class="py-2">
                2.3. "Intellectual Property Rights", which may also be referred as IPR, shall mean and include all
                registered and unregistered trademarks, copyright in all forms, including but not limited to the contents of
                the Site, images, text, illustrations, audio clips, trademarks, logos, labels, video clips, software and
                coding; industrial designs, patents, inventions, domain names, trade secrets, methodology, processes,
                features, functionality, End User Information and common law rights in the aforesaid, which are associated
                with the Company, Services or the System.
            </p>
            <p class="py-2">
                2.4. "Person" shall mean a person, and includes any individual, corporation, firm, partnership, joint
                venture,
                association, organisation, trust, state or Governmental Authority or other legal entity (in each case,
                whether having separate legal personality or not).
            </p>
            <p class="py-2">
                2.5. "Healthcare practitioner" or "Healthcare practitioners" shall mean and include commonly identified
                healthcare professional(s), such as doctors, physiotherapists, and such other medical or paramedical
                professional(s) registered on the Site.
            </p>
            <p class="py-2">
                2.6. "End User Services" shall mean End User Services provided by the Company through its Site and System.
                More
                details of the End User Services are available in the section titled, End User Services, of the End User
                Terms and Conditions..
            </p>
            <p class="py-2">
                2.7. "Site" shall mean and include, in whole or in part, the internet resource and website known as rxix.in,
                including but not limited to all its associated sub-domains, any accompanying or associated data,
                applications, utilities or interface.
            </p>
            <p class="py-2">
                2.8. "Site" shall mean and include, in whole or in part, the internet resource and website known as rxix.in,
                including but not limited to all its associated sub-domains, any accompanying or associated data,
                applications, utilities or interface.
            </p>
            <p class="py-2">
                2.9. "Use" shall mean and include any use of the Site or Services by a Person, including, without
                limitation, HCPs, Registered HCPs, unregistered HCPs, and any other Persons who accesses or visits the Site
                in the role of a HCP.
            </p>
            <p class="py-2">
                2.10. "HCP" and "HCPs" shall mean and include a registered HCP, unregistered HCP, or any Healthcare person
                who Uses the Site, for whom or on whose behalf, the HCP is using the Site.
            </p>
            <p class="py-2">
                2.11. "HCP Account Information" shall mean and include the name, email address, mobile number, date of
                birth,
                gender, city, pincode and any other information as may be required by the Company in relation to the HCP
                Account.
            </p>
            <p class="py-2">
                2.12. "HCP Information" shall mean and include HCP Account Information, HCP-generated content, information
                generated, directly or indirectly, subject to the privacy policy of the Company, with respect to the HCP
                Account, during the process of obtaining HCP Services by the HCP from the Site, or otherwise.
            </p>
            <p class="py-2">
                2.13. “Services” shall mean “HCP Services”.
            </p>
            <p class="py-2">
                2.14. "HCP Services" shall mean and include the following offered by the Company from time to time through
                the Site. More details of the End User Services are available in the section titled, HCP Services.
            </p>
            <p>3. <span class="HcpSubHeading">ACCEPTANCE OF TERMS</span></p>
            <p class="py-2">
                3.1. This Agreement applies to the Company and HCP and governs the provision of access to HCPs of the Site,
                and/or the use by HCPs of any HCP Services provided on or through the Site.
            </p>
            <p class="py-2">
                3.2. By enabling the checkbox " I agree to RxIx Terms and Conditions " at the time of registration or by the
                mere
                use of the HCP Services provided by the Company through Site by the HCP, the HCP shall be and be deemed to
                be bound by this Agreement, and to have read, understood and unconditionally accepted this Agreement in its
                entirety for availing in all matters concerning its access of the site and the use of the HCP Services
                provided on or through the Site.
            </p>
            <p class="py-2">
                3.3. By using the Site or accessing any material, information or services through the Site, the HCP agrees,
                admits, confirms and declares that the HCP has completed 18 years of age as on date of this Agreement, and
                that the HCP has fully read and understood the Terms and Conditions (also referred as "HCP Terms of Use") as
                set forth in this Agreement, without any impairment in judgment resulting from (but not limited to) mental
                illness, mental handicap, intoxication, medication, or any other health or other problem that could impair
                judgment.
            </p>
            <p class="py-2">
                3.4. In case the HCP has any issue or difficulty with the understanding of this Agreement or any of its
                clause or portion, you are advised not to use the Site and if the HCP uses the Site, it shall be presumed
                and signified that HCP has agreed to be bound by the terms and conditions of the Agreement, without any
                objection or reservation.
            </p>
            <p class="py-2">
                3.5. The Company may amend any policies or supplemental terms (including the Community Guidelines) related
                to
                the Services from time to time. The Company will provide you with at least 30 days' written notice in the
                event of a material change to any policies or supplemental terms that detrimentally affects your rights
                under these Terms. Amendments will be effective upon the Company’s posting of such amended policies or
                supplemental terms on the applicable Service. Your continued access or use of the Services after such
                posting, or after the expiry of the notice period (whichever is later), constitutes your consent to be bound
                by the Terms, as amended.
            </p>
            <p class="py-2">
                3.6. By the mere use of or access to the Site, you shall be contracting with Company, and these terms and
                conditions including the privacy policy, constitute your binding obligation.
            </p>
            <p class="py-2">
                3.7. Further, by the mere use of or access to the Site, you agree and declare that you are intending to use
                the HCP Services offered by the Company through Site, on your own volition, free will, without any undue
                influence, force or coercion, while in sound mind, and disposing capacity, and your being legally capable of
                contracting in law.
            </p>
            <p class="py-2">
                3.8. The Company is, and the HCP accepts that the Company is, the owner, author and publisher of the Site
                and
                the operator of the System associated with the Site for providing Services.
            </p>
            <p class="py-2">
                3.9. You may terminate these Terms at any time, for any reason. For further information on termination,
                please refer to the Termination section of this document.
            </p>
            <p class="py-2">
                3.10. The Company reserves the right, at its sole discretion, to change, modify, add to, or remove any part
                of the HCP Services or portions of these HCP Terms of Use at any time without any prior written notice to
                the HCP, and any such change, modification, addition or removal (hereinafter referred as "Modifications")
                shall be considered as a part of these HCP Terms of Use. It is the HCP's responsibility to review these HCP
                Terms of Use periodically for updates/changes. HCP's continued use of and access to the Site, following the
                Modifications, will mean that the HCP accepts and agrees to the Modifications.
            </p>
            <p class="py-2">
                3.11. Without limiting its other rights under these Terms, the Company may immediately restrict or
                deactivate
                your access to the Services if you breach the Community Guidelines at any time.
            </p>
            <p class="py-2">
                3.12. If you do not wish to be bound by any part of this Agreement, you are not allowed to and shall not use
                the Site or any of Services offered by the Company or the Site, any such use of the Site or any of the
                Services shall not be valid and shall not vest any rights or entitlements in you and shall be a breach of
                this Agreement by you.
            </p>
            <p>4. <span class="HcpSubHeading">PRIVACY POLICY</span></p>
            <p class="py-2">
                4.1. The Company has established a Privacy Policy that explains to HCPs how their personal information is
                collected and used. The Privacy Policy is located the domain, www.rxix.in/policiesandterms.
            </p>
            <p class="py-2">
                4.2. The Privacy Policy contains information about how HCPs may seek access and correct their personal
                information held by the Company and how they may make a privacy complaint.
            </p>
            <p class="py-2">
                4.3. The Privacy Policy is hereby incorporated into the HCP Terms of Use set forth herein. Your use of this
                Site is governed by the Privacy Policy.
            </p>
            <p class="py-2">
                4.4. The Company may disclose HCP’s personal information to third parties any necessary information
                (including your contact information), if there is a complaint, dispute or conflict, which may include an
                accident, involving you and a third party and such information or data is necessary to resolve the
                complaint, dispute or conflict. The Company may also provide to a law enforcement agency, statutory body,
                governmental agency and/or investigative agency any necessary information (including your contact
                information), if required by law or in furtherance of any investigation involving you and a third party, and
                such information or data is necessary towards the enquiry/investigation that is being carried out by the
                said body and/or agency.
            </p>
            <p>5. <span class="HcpSubHeading">COMMUNITY GUIDELINES</span></p>
            <p class="py-2">
                5.1. The Company has established Community Guidelines that explains to end users how we would like end users
                to post, interact, reply, comment, etc. on the Site. The Community Guidelines are located at the domain,
                www.rxix.in/policiesandterms.
            </p>
            <p class="py-2">
                5.2. The Community Guidelines contains information about how violations of the guidelines will be handled
                and
                how they may make a guidelines violation complaint.
            </p>
            <p class="py-2">
                5.3. The Community Guidelines are hereby incorporated into the End User Terms of Use set forth herein that
                Your use of this Site is governed by the Community Guidelines.
            </p>
            <p>6. <span class="HcpSubHeading">RELATIONSHIP AND AGREEMENT BETWEEN THE COMPANY, END USERS, AND THE HEALTHCARE
                    HCPS</span></p>
            <p class="py-2">
                6.1. The Company is an intermediary as per Section 2(w) of the Information Technology Act, 2000.
            </p>
            <p class="py-2">
                6.2. The Company is not and cannot be a party to or control in any manner any dealing between the Site's End
                Users and the HCPs and the HCP hereby understands and accepts as under:
            </p>
            <p class="py-2">
                6.2.1. The Company does not practice medicine or care services and does not interfere with the practice of
                medicine and is not involved in providing healthcare or medical advice or diagnosis or home care or
                diagnostic End User Services.
            </p>
            <p class="py-2">
                6.2.2. All or any of the HCPs who may provide services through our Site are independent professionals and/or
                organisations/companies who are providing services in their legal and professional capacity.
            </p>
            <p class="py-2">
                6.2.3. The `Company does not make any representations or warranties with respect to the HCPs or the quality
                of the healthcare End User Services they may provide. Accordingly, the Company shall not be held responsible
                for any interaction or associated issues between the End User and the HCP.
            </p>
            <p class="py-2">
                6.3. The HCP understands and agrees that the relationship between the End User and the HCP, is and shall
                always be independently governed by the rules and regulations that may be prescribed by the HCP, and/or
                under Applicable Laws. The Company does not claim any control over any such rules and regulations and
                Applicable Laws.
            </p>
            <p class="py-2">
                6.4. There is and shall always be a separate and independent contractual relationship between the End User
                and the HCP, without any involvement or control or interference of the Company. Accordingly, there is and
                shall never be any privity of contract between the Site, the End User and the HCP.
            </p>
            <p class="py-2">
                6.5. The HCP may independently define an individual or specific agreement with the End User to whom you will
                provide your services through the Site.
            </p>
            <p class="py-2">
                6.6. The HCP shall note that the Company is not privy to any separate or independent Agreements that HCP has
                with the End User, which independently govern their inter-se relationship, and shall be on a
                principal-to-principal basis between them.
            </p>
            <p class="py-2">
                6.7. The Company shall not be held liable for any interaction and associated issues, including but not
                limited to the outcome and/or service issues, between the HCP and the End User/s. Hence, the Company shall
                not be held liable for such interactions. If you decide to provide End User Services to an End User, you do
                so at your entire risk and liabilities in all respects. The Company shall not be liable for any reason
                whatsoever for the End User Services provided by you, and we bear no liability for the consequences to you
                or to the End User
            </p>
            <p>7. <span class="HcpSubHeading">HCP OBLIGATIONS, UNDERTAKINGS AND CONSENT</span></p>
            <p class="py-2">
                7.1. Prior to availing any Services, or completion of any transaction on the Site, you will have to register
                and become a "Registered HCP".
            </p>
            <p class="py-2">
                7.2. All HCPs shall be of 18 years of age or above and shall register separately to avail the Services. The
                legal age of majority of 18 years shall apply and bind the HCP irrespective of the legal age of majority
                that is applicable to the jurisdiction in which the HCP resides or is domicile.
            </p>
            <p class="py-2">
                7.3. When a HCP registers on the Site, the HCP shall be required to create an account ("HCP Account") by
                providing the information comprising the name, email address, mobile number, date of birth, gender, city,
                pin code and certain other information as may be required by the Company.
            </p>
            <p class="py-2">
                7.4. The verification of mobile number and / or the email shall be compulsory without which the Company will
                not create a HCP Account.
            </p>
            <p class="py-2">
                7.5. Use of the Site and the Services by the HCP shall be solely at the discretion of the Company. The
                Company reserves its right to add, delete or discontinue its any Service or Site in part or whole.
            </p>
            <p class="py-2">
                7.6. The Company reserves, at its sole discretion, the right to refuse any HCP to access the Site or avail
                any Services, without assigning any reason.
            </p>
            <p class="py-2">
                7.7. The Company also reserves, at its sole discretion, the right to block any HCP from creation of any HCP
                Account or block any Registered HCP from accessing Site or Services, without assigning any reason.
            </p>
            <p class="py-2">
                7.8. If a HCP registers on the Site and successfully gets a HCP Account, the HCP shall be responsible for
                maintaining the confidentiality of the account, and the HCP shall be responsible for all activities that
                occur under the HCP's Account.
            </p>
            <p class="py-2">
                7.9. The HCP hereby declares and verifies that all information provided by the HCP is true, accurate and
                genuine. The HCP hereby agrees that in case any information provided by the HCP is not true or accurate or
                genuine or complete, or the Company has reasonable grounds to suspect that such information is false,
                misleading, untrue, inaccurate, not current or incomplete, or not in accordance with these HCP Terms of Use,
                then the Company shall have the right to indefinitely suspend or terminate or block access of HCP's Account
                on the Site.
            </p>
            <p class="py-2">
                7.10. The HCP hereby agrees, declares and warrants that the HCP is fully authorized and has taken all
                requisite approvals from any person on whose behalf (if any) HCP is acting on the Site.
            </p>
            <p class="py-2">
                7.11. The HCP agrees that the HCP shall be solely responsible for all usage of the HCP's Account, whether
                authorized by the HCP or not.
            </p>
            <p class="py-2">
                7.12. The HCP consents to immediately notify the Company of any actual or suspected unauthorized use or
                breach of the HCP's Account. The HCP hereby agrees that the Company shall not be liable for any direct or
                indirect damages, losses, costs, charges, expenses, etc. caused to the HCP by any unauthorized use of the
                HCP Account, and the HCP shall be liable for all damage and loss caused to the Company or others, due to
                such unauthorized use.
            </p>
            <p class="py-2">
                7.13. HCP agrees and undertakes that the HCP will not or attempt to:
            </p>
            <p class="py-2">
                7.13.1. impersonate any other HCP, person or entity,
            </p>
            <p class="py-2">
                7.13.2. misrepresent his affiliation with the Company (there being no such affiliation);
            </p>
            <p class="py-2">
                7.13.3. use the Site or Services in violation of any local, state, national or international laws, rules or
                regulations;
            </p>
            <p class="py-2">
                7.13.4. use the Services, Site or System in any manner that exceeds the scope of the right to use the Site,
                System and Services.
            </p>
            <p class="py-2">
                7.13.5. use abusive, inappropriate or derogatory language while communicating with End User through the
                Site

            </p>
            <p class="py-2">
                7.14. The HCP agrees that the delivery of any communications from the Company shall be deemed to be
                effective, when sent by the Company, through any mode of communication, regardless of whether the HCP reads
                the communication on receipt of it, or whether the HCP actually receives the communication.
            </p>
            <p>8. <span class="HcpSubHeading">DISCLAIMER OF WARRANTIES, GUARANTEES AND REPRESENTATIONS</span></p>
            <p class="py-2">
                8.1. HCP agrees that use of the HCP Services is entirely at HCP's own risk, cost and consequences. The
                Service is provided on 'as is' basis, without warranty, or guarantee of any kind, and/or any responsibility
                or liability either express or implied, or whether vicarious, or contingent.
            </p>
            <p class="py-2">
                8.2. We make no representations concerning the completeness, accuracy or utility of any information in the
                System, or concerning the qualifications or competence of individuals who placed it there.
            </p>
            <p class="py-2">
                8.3. While every effort has been made to ensure that the information hosted on this Site is accurate and
                correct, the Company does not warrant the accuracy of information obtained from Site or that it will not
                violate or infringe any third party right in any manner, whatsoever.
            </p>
            <p class="py-2">
                8.4. Any right granted to the HCP to use the Services offered by the Company by its Site is personal,
                non-exclusive, and non-transferable and a limited and revocable (at the discretion of the Company)
                permission to use the Site and HCP Services.
            </p>
            <p class="py-2">
                8.5. The Company disclaims all warranties including, but not limited to, the implied warranty of
                merchantability and suitability for any Services.
            </p>
            <p class="py-2">
                8.6. Company also, does not warrant that:
            </p>
            <p class="py-2">
                8.6.1. this Site will be constantly available, or available at all;
            </p>
            <p class="py-2">
                8.6.2. any or all the HCP Services or any other Services on the Site will be constantly available, or
                available at all;
            </p>
            <p class="py-2">
                8.6.3. HCP will be able to access his or her account at any or all times;
            </p>
            <p class="py-2">
                8.6.4. the information on this Site is complete, true, accurate or non-misleading;
            </p>
            <p class="py-2">
                8.6.5. the quality of any Services, information, or any other thing obtained by the HCP through the Site
                will
                meet the HCP's expectations, need or purpose;
            </p>
            <p class="py-2">
                8.6.6. the HCP or any other person will be able to use any Services;
            </p>
            <p class="py-2">
                8.6.7. the HCP will be able to complete any or all the transactions on the Site; and
            </p>
            <p class="py-2">
                8.6.8. the Site, information, content, materials, or HCP Services included on or otherwise made available to
                the HCP through the Site; their servers; or electronic communication sent from the Company or the Site, are
                free of viruses or any other harmful components.
            </p>
            <p class="py-2">
                8.7. The Company assumes no responsibility, and shall not be liable for, any damages to, or viruses that may
                infect HCP's equipment on account of HCP's access to, use of, or browsing the Site or the downloading of any
                material, data, text, images, video content, or audio content from the Site. If a HCP is dissatisfied with
                the Site, HCP's sole remedy is to discontinue using the Site.
            </p>
            <p>9. <span class="HcpSubHeading">HCP SERVICES</span></p>
            <p class="py-2">
                The Company offers services for the HCPs registered on the Site. While some HCP Services available on the
                Site are provided at no charge, for free, there are certain HCP Services for which charges will be incurred
                and payable by you.
                The specific terms relating to such HCP Services, without prejudice and in addition to all other HCP Terms
                of Use are as under.
            </p>
            <p class="py-2">
                9.1. Enhance your visibility among End Users and the public about the consultation services offered by the
                HCP
            </p>
            <p class="py-2">
                9.1.1. The Company recognizes that it is necessary for the public at large to know about the services
                offered
                by healthcare practitioners so that the public may connect with the HCP and seek consultation. Such
                consultation may be at the HCP’s workplace or at the End User’s residence.
            </p>
            <p class="py-2">
                9.1.2. The Company provides the HCP, at no charge, a facility for you in your HCP Account Information to
                indicate the areas of consultations as well as the modes of consultation that you will provide to the End
                Users of the Site. You may modify these information at any stage. However, you shall provide at least one
                area of specialization and at least one mode of consultation as part of your HCP Account Information.
            </p>
            <p class="py-2">
                9.1.3. Upon verification for data entry errors, the HCP Account Information shall be published as a HCP
                page.
                As such, the HCP page contains information that is self-reported by the HCP. It is the responsibility of the
                HCP to keep the page updated and current. The Company may not review for authenticity of the information
                provided therein.
            </p>
            <p class="py-2">
                9.1.4. The HCP may review the page and if there are any errors, bring to the attention of the Company about
                the same. The Company is not responsible for any errors in the page. The Company will not be held liable for
                any loss or damage to the HCP’s practice because of any errors in the HCP’s page.
            </p>
            <p class="py-2">
                9.1.5. The HCP’s page will be linked to the HCP’s name at most places on the Site and End Users will be able
                to view the page’s contents from most parts of the Site. The HCP’s page may also be made available to Search
                engines to index and display it in their search results.
            </p>
            <p class="py-2">
                9.2. Receive home visit requests from End Users
            </p>
            <p class="py-2">
                9.2.1. Of the HCPs who have indicated that they are willing to provide consultation to End Users at the End
                User’s home, the Company may enter into a separate agreement with select HCPs to deliver Home-based services
                to the Site’s End Users. The selection will be based on business needs. The Company has the right to choose
                the HCPs with whom they are willing to enter into an agreement. The selected HCP may choose to not enter
                into an agreement with the Company. Please refer Section 6 that explains the relationship between the
                Company, the End User, and the HCP in such situations.
            </p>
            <p class="py-2">
                9.2.2. The Company shall explain the modus operandi of End User request management, which inter alia
                includes from request submission by End User to request closure by the HCP, to such HCPs who are selected
                and who agree to enter into an agreement with the Company.
            </p>
            <p class="py-2">
                9.2.3. The HCP shall accept the HCPs consultation charges to End Users. The charges may vary based on the
                geography of consultation.
            </p>
            <p class="py-2">
                9.2.4. The Company will collect the consultation charges from the End Users on your behalf. The amount will
                be remitted to you on an agreed periodicity.
            </p>
            <p class="py-2">
                9.2.5. Towards organizing and facilitating the payment from End Users, the Company may charge an agreed
                Payment Processing Fee from you. This amount will be adjusted from the consultation charges that will be due
                to you from the Company.
            </p>
            <p class="py-2">
                9.2.6. The HCP to whom the Company assigns a home visit request may decline to take up the request. It is
                suggested that the HCP decline a request within a reasonable time from the moment a request is assigned to
                the HCP. If the Company observes that the HCP repeatedly declines a number of requests or that the HCP takes
                more than a reasonable time to decline a request, the Company may choose not to assign home visit requests
                to the HCP despite entering into an agreement with the HCP.
            </p>
            <p class="py-2">
                9.2.7. The HCP may choose to cancel a request after accepting a home visit request. It is suggested that the
                HCP cancels a request within a reasonable time from the moment a request is assigned to the HCP. If the
                Company observes that the HCP repeatedly cancels a number of requests or that the HCP takes more than a
                reasonable time to cancel a request, the Company may choose not to assign home visit requests to the HCP
                despite entering into an agreement with the HCP.
            </p>
            <p class="py-2">
                9.2.8. It may also happen that the End User cancels a home visit request after the HCP to whom the request
                is assigned, accepts the request. If the End User cancels his home visit request before the request is
                accepted by you, no compensatory amount will be payable to you. If the End User cancels his home visit
                request after the request is accepted by you, a compensatory amount will be paid to the HCP. The calculation
                of the amount that will be refunded is as follows.
            </p>
            <p class="py-2">
                9.2.8.1. When the time difference between the request accepted time and the scheduled visit time is 24 hours
                or more, no amount will be paid.
            </p>
            <p class="py-2">
                9.2.8.2. When the time difference between the request accepted time and the scheduled visit time is greater
                than 6 hours but less than 24 hours, 10% of the consultation amount payable to you will be paid.
            </p>
            <p class="py-2">
                9.2.8.3. When the time difference between the request accepted time and the scheduled visit time is less
                than 6 hours, 25% of the consultation amount payable to you will be paid.
            </p>
            <p class="py-2">
                9.3. Store, access, and retrieve an End User’s consultation records
            </p>
            <p class="py-2">
                9.3.1. The Company makes available to all registered HCPs a facility to store End User consultation case
                sheets and related prescriptions online. These records will be owned by the End User. The creation and
                uploading of the records by the HCP will be on behalf of the End Users to whom they provide consultation.
                The specific terms relating to such storage are below, without prejudice and in addition to all other HCP
                Terms of Use.
            </p>
            <p class="py-2">
                9.3.1.1. A storage facility is created to every End User after they have signed up and explicitly accepted
                all these Terms of Use.
            </p>
            <p class="py-2">
                9.3.1.2. An End User may add dependents to his account and each person is provided with a unique storage
                facility, which the End User owns.
            </p>
            <p class="py-2">
                9.3.1.3. As part of your consultation to the End User, you may upload to the Site the consultation case
                sheets and the prescription documents that You create. Such case sheets and prescriptions will be stored in
                the End User’s or the dependent’s secure storage facility.
            </p>
            <p class="py-2">
                9.3.1.4. End Users own the records in the storage facility and they have the right to provide HCPs access to
                their records. Only when the right is exercised can a HCP access an End User’s records or the online records
                of an End User’s dependent.
            </p>
            <p class="py-2">
                9.3.1.5. The End User may, at any time, revoke the access provided to You.
            </p>
            <p class="py-2">
                9.3.2. As part of the consultation, you may also get to access the End User’s records of consultation with
                other HCPs registered on the Site.
            </p>
            <p class="py-2">
                9.4. Manage consultations with a team of HCPs
            </p>
            <p class="py-2">
                9.4.1. The Company may provide a facility to a HCP on the Site to provide consultation to his End Users
                using a team of HCPs.
            </p>
            <p class="py-2">
                9.4.2. Unless otherwise agreed, such a facility will be on a subscription basis. Such Subscription fees is
                payable before the HCP’s team starts to use such facility. However, the Company may provide a trial period
                to the HCP.
            </p>
            <p class="py-2">
                9.4.3. The HCP is free to cancel the subscription and seek a refund of the future charges after providing a
                30-day notice.
            </p>
            <p class="py-2">
                9.5. List Your clinic in the search results of RxIx-supported clinics
            </p>
            <p class="py-2">
                9.5.1. RxIx is a Site that supports the practice of HCPs by providing access to appropriate information
                based on a principle called Information Therapy. The Company has come up with its own framework to align
                with the principle. All HCPs who are registered with the Site are supported by the framework contents, which
                helps to provide good End User care. Hence, the practices of HCPs registered with the Site are called
                RxIx-supported clinics.
            </p>
            <p class="py-2">
                9.5.2. End Users and public are educated to seek consultation from informed HCPs or RxIx-supported clinics.
            </p>
            <p class="py-2">
                9.5.3. End Users and public will be able to search for RxIx-supported clinics in a particular geography or
                such other filters that the Company may provide to the End User and the public. When End Users seek to
                search for RxIx-supported clinics. The Site will list the clinics and HCPs registered on the Site, based on
                the values in your HCP Account Information.
            </p>
            <p class="py-2">
                9.5.4. The search results will also be linked to the respective HCP’s page. The Company will endeavor that
                the links are right and are working. However, there is no assurance that the links will always work. The
                Company is not responsible if there are any failures to link your listing in the search results with your
                HCP’s page. The Company is not responsible, and you also agree that you will not claim any loss to you due
                to the failure in linking the search results with your page.
            </p>
            <p class="py-2">
                9.5.5. End Users and public are free to connect with you directly. However, the Company is under no
                obligation to ensure that End Users take consultation from you.
            </p>
            <p class="py-2">
                9.5.6. The Company's ranking algorithm for listing the HCPs and their clinics is a fully automated system
                that lists the HCPs, their profile and information regarding their practice and HCP Services on the Site.
                This listing of HCPs does not represent any fixed objective ranking or any preferential or meritorious
                endorsement by the Company. The Company will not be liable for any change in the listing order of the HCPs,
                which may take place from time to time when End Users search for relevant HCP Services. The Company, in no
                event, will be held responsible for the accuracy and the relevancy of the listing order of the HCPs on the
                Site.
            </p>
            <p class="py-2">
                9.6. Join patient support groups
            </p>
            <p class="py-2">
                9.6.1. The Company believes that it is helpful for an End User to connect with people having similar health
                conditions. In line with this belief, the Company has created multiple disorder-specific support groups. The
                disorders may be aligned with the practice areas in the HCP Information.
            </p>
            <p class="py-2">
                9.6.2. End Users are enrolled into appropriate patient support groups when they register on the Site, based
                on the healthcare topics that are of interest to them. End Users may also voluntarily join any of the
                support groups at any time. They may also leave any support group at any time.
            </p>
            <p class="py-2">
                9.6.3. To enhance your visibility in the community, you may join any of the support groups. Based on the
                values you select in your HCP Account Information, You will, at no charge, automatically become a member of
                the appropriate disorder-specific groups. You will have the option, at any time, to exit such support groups
                and also re-join.
            </p>
            <p class="py-2">
                9.6.4. The Company may, at its discretion, limit the number of support groups that a HCP may join.
            </p>
            <p class="py-2">
                9.6.5. You will have the option to add Your patients to appropriate disorder-specific groups when you
                provide consultation.
            </p>
            <p class="py-2">
                9.6.6. As a member of disorder-specific groups, you will be able to publish messages and posts to the group
                members as well as respond to the queries of the group members.
            </p>
            <p class="py-2">
                9.7. Manage and run your online community
            </p>
            <p class="py-2">
                9.7.1. The Company believes that a good way for a HCP to build an identity in the community is to connect
                with the community of his patients as well as End Users who are interested in his practice. To this end, the
                Company makes available a facility for a HCP, at no charge, to manage and run a community of his patients
                and End Users.
            </p>
            <p class="py-2">
                9.7.2. After you have signed up and explicitly accepted all these Terms of Use, the Site will create an
                exclusive online community for you. On the Site, the Company will provide you with a link to your community
                that you can share with your patients and others.
            </p>
            <p class="py-2">
                9.7.3. Your online community on RxIx will be mentioned on your HCP’s page once it is approved. This page and
                the link to your online community will be accessible to all End Users. They will also be accessible to the
                public through the search engine results.
            </p>
            <p class="py-2">
                9.7.4. When you offer consultation to End Users, they will be added to your online community. When you offer
                consultation to the public and upload their records to the Site, the Site will send out an invitation to
                them to join your online community and also provide them access to all the features of the Site.
            </p>
            <p class="py-2">
                9.7.5. Your community members may, at any time, also choose to drop out of your community.
            </p>
            <p class="py-2">
                9.7.6. The Company offers the facility for a HCP to invite people who are not yet part of the Site or who
                are yet to be provided consultation.
            </p>
            <p class="py-2">
                9.7.7. The people whom You invite may choose to accept your invitation or reject your invitation or choose
                not to take action.
            </p>
            <p class="py-2">
                9.7.8. There is no limit on the number of invitations you may send. The Company does not pay you for sending
                out the invitations nor does it charge you for the same.
            </p>
            <p class="py-2">
                9.7.9. The people who accept your invitation and join the Site will also become part of your online
                community.
            </p>
            <p class="py-2">
                9.8. Use a library of patient care resources and healthcare information
            </p>
            <p class="py-2">
                9.8.1. As part of the framework that aligns with the principle of Information Therapy, the Company makes
                available a facility for HCP to access a library of resources. These resources may include, but not limited
                to patient explanatory resources, DSS charts, treatment guidelines, and manuals in the form of videos,
                texts, and images. Some of the resources may be links to third party sites. Access to such links may be
                governed by the policies and rules of such third-party sites.
            </p>
            <p class="py-2">
                9.8.2. Access is provided only after you have signed up and explicitly accepted all these HCP Terms of Use.
            </p>
            <p class="py-2">
                9.8.3. The Company does not claim authenticity of any information provided in the library. The information
                is provided on an "as is" basis and the Company does not validate the said information and makes no
                representation or assurance in connection therewith.
            </p>
            <p class="py-2">
                9.9. Receive post-consultation support to guide and educate patients
            </p>
            <p class="py-2">
                9.9.1. The Company understands that a HCP spends a lot of time explaining to patients about their disorder,
                the treatment, and such other related information as part of the consultation, while also looking to cut
                down the time it takes.
            </p>
            <p class="py-2">
                9.9.2. The Site provides you with an option to take our post consultation support. The support services may
                be free of charge or payable by your patients.
            </p>
            <p class="py-2">
                9.9.3. If you choose to allow us to provide post consultation support to your patients, depending on the
                practice areas that you select in your HCP Information, the Company will send your community members and
                patients with relevant third-party patient resources. These resources may include, but not limited to,
                videos, texts, and images. Some of the resources may be links to third party sites. Access to such links may
                be governed by the policies and rules of such third-party sites. The Company does not claim authenticity of
                any information provided in the library. The information is provided on an "as is" basis and the Company
                does not validate the said information and makes no representation or assurance in connection therewith.
            </p>
            <p class="py-2">
                9.9.4. The Site may also publish case sheet-related and prescription-related information to your patients.
            </p>
            <p class="py-2">
                9.10. Publish your wisdom to RxIx End Users and the public, and answer End User queries
            </p>
            <p class="py-2">
                9.10.1. The Company is on a mission to wean the public and the End Users away from unreliable healthcare
                information.
            </p>
            <p class="py-2">
                9.10.2. To this end, the Company provides a facility to HCPs registered on the Site, with no charge, to
                publish their wisdom about the disorders they provide care, the general prognosis, the treatment options
                available, etc. The Company also believes that this facility would be an opportunity for the HCPs to make
                themselves visible in the community.
            </p>
            <p class="py-2">
                9.10.3. The posts that HCPs publish will reach their community members as also members of the
                disorder-specific groups to which the HCP also belongs. In addition, these posts will also be available for
                the various search engines to index and display in their search results.
            </p>
            <p class="py-2">
                9.10.4. The Company would not be reviewing or editing or polishing any of the posts that the HCP publishes.
            </p>
            <p class="py-2">
                9.10.5. The HCP will be able to publish posts in English and in other popular Indian languages.
            </p>
            <p class="py-2">
                9.10.6. The published posts will be considered as HCP-generated content. The content will be owned by the
                Company and the HCP agrees that the Company can use the content as it deems fit.
            </p>
            <p class="py-2">
                9.10.7.The Company offers a facility to End Users to raise their queries on the Site.
            </p>
            <p class="py-2">
                9.10.8. The queries are sent by the Site’s algorithm to all members of the group and communities that the
                End User is a member. If You are a member of such a group or community, You will also receive the queries
                raised by an End User.
            </p>
            <p class="py-2">
                9.10.9. You may then choose to reply to the query. The Company will not edit or make changes to your reply.
            </p>
            <p class="py-2">
                9.10.10. The query together with the replies will become part of the Company’s asset. They can be searched
                by other End Users.
            </p>
            <p class="py-2">
                9.10.11. The query together with the replies may also be available to search engines to index and deliver on
                their search result pages.
            </p>
            <p class="py-2">
                9.11. Become member of communities of HCPs and seek case inputs from fellow HCPs registered with RxIx
            </p>
            <p class="py-2">
                9.11.1. Immediately upon registration and providing relevant details, you may be enrolled into select
                communities of HCPs.
            </p>
            <p class="py-2">
                9.11.2. In the interest of good patient care, the Company provides a facility to HCPs to connect and discuss
                with other HCPs on the Site.
            </p>
            <p class="py-2">
                9.11.3. Once an End User provides You access to his online storage facility, the Company provides You the
                facility to share the End User’s records with other HCPs on the Site to seek their wisdom and insights.
            </p>
            <p class="py-2">
                9.11.4. The Company may, at its discretion, charge Subscription fees to HCPs to make use of such facility.
            </p>
            <p>10. <span class="HcpSubHeading">FEES AND PAYMENT</span></p>
            <p class="py-2">
                10.1. The Company may charge you a subscription fee (“Subscription Fee”) for select HCP Services. The
                Subscription Fee shall be charged from you at the time of accessing such Services on the Site. The prices of
                the Services will be displayed when you avail such Services.
            </p>
            <p class="py-2">
                10.2. The prices displayed on the Site may or may not include any taxes, including Service Tax, Goods and
                Services Tax, VAT, etc., as applicable and the same will, be additionally payable by the HCP.
            </p>
            <p class="py-2">
                10.3. For making the payment, the HCP will be directed to the portal of the third party, who has been
                outsourced by the Company, to receive payment.
            </p>
            <p class="py-2">
                10.4. For any of the payment method opted by the HCP for making payment on the portal of the outsourced
                party/agency/service provider, and thereafter by entering banking information and details on that portal
                while making payment, the Company does not assume any liability, and shall never be responsible for any
                direct or indirect costs, charges, expenses, damage or loss suffered by the HCP, as the Company has
                outsourced third party(s) for receiving such payment, who is an independent party, and the Company has no
                access or control over any information and details inserted by the HCP when making or attempting to make any
                payment. Unless otherwise stated, all fees shall be quoted in Indian Rupees (INR).
            </p>
            <p class="py-2">
                10.5. The liability of the Company to render Services, shall arise only after receipt of the payment, on
                successful completion of the payment transaction.
            </p>
            <p class="py-2">
                10.6. The Company may, at its sole discretion, introduce any offers/ discounts/ incentives on the Services,
                or introduces new service and/ or modify some or all of the existing services offered on the Site. In such
                an event, the Company reserves the right to charge fees for the new services offered or amend/introduce fees
                for existing services, without any prior intimation.
            </p>
            <p class="py-2">
                10.7. The Company may choose to discontinue or modify any incentive(s) or discounts at its sole discretion,
                without any prior intimation or notice.
            </p>
            <p class="py-2">
                10.8. HCP shall be solely responsible for compliance of all applicable laws of India while making any
                payment to Company.
            </p>
            <p class="py-2">
                10.9. The Company may, at its sole discretion, create promotional codes that may be redeemed for Account
                credit, or other features or benefits related to the Services, subject to any additional terms that the
                Company establishes on a per promotional code basis ("Promo Codes"). You agree that Promo Codes: (i) must be
                used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or
                transferred in any manner, or made available to the general public (whether posted to a public form or
                otherwise), unless expressly permitted by the Company; (iii) may be disabled by the Company at any time for
                any reason, without liability to the Company; (iv) may only be used pursuant to the specific terms that the
                Company establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use.
                The Company reserves the right to withhold or deduct credits or other features or benefits obtained through
                the use of Promo Codes by you or by any other HCP, in the event that the Company reasonably determines or
                believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of
                the applicable Promo Code terms or these Terms.
            </p>
            <p class="py-2">
                10.10. The Company reserves the right to establish, remove and/or revise the Subscription Fee for any or all
                services obtainable through the use of the HCP Services at any time in the Company’s sole discretion. The
                Company may, from time to time, provide certain HCPs with promotional offers and discounts that may result
                in different amounts charged for the same or similar services obtained through the use of the Services, and
                you agree that such promotional offers and discounts, unless also made available to you, shall have no
                bearing on your use of the Services or the charges applied to you.
            </p>
            <p>11. <span class="HcpSubHeading">VIOLATIONS OF SYSTEM OR NETWORK SECURITY</span></p>
            <p class="py-2">
                11.1. Subject to the terms of this Agreement, and any other agreement that the Company may have with the
                HCP, the HCP shall act in an honest, bonafide and ethical manner at all times when the HCP accesses the Site
                or Uses any of the HCP Services and undertakes to provide all co-operation reasonably required by Company in
                relation to the Site and Services.
            </p>
            <p class="py-2">
                11.2. Any violation or breach of System or network security is prohibited and actionable, and any such act
                may result in the HCP facing criminal and civil liability and being denied access to the Site. Violations
                and breach of Site, System or network security may include, but are not limited to, the following:
            </p>
            <p class="py-2">
                11.2.1. introduction of any virus, logic bomb, harmful code and/or Trojan horse to the Site or Service.
            </p>
            <p class="py-2">
                11.2.2. flaming, hacking or otherwise obtaining unauthorized access to or use of data, systems or networks,
                including any attempt to probe, scan or test the vulnerability of a system or network.
            </p>
            <p class="py-2">
                11.2.3. flaming, hacking or otherwise obtaining unauthorized access to or use of data, systems or networks,
                including any attempt to probe, scan or test the vulnerability of a system or network.
            </p>
            <p class="py-2">
                11.2.4. interfering with any HCP, host or network, including mail bombing, flooding, deliberate attempts to
                overload a system and broadcast attacks.
            </p>
            <p class="py-2">
                11.2.5. interference with any other HCP's use and enjoyment of the Site or Service, or any other
                individuals' use and enjoyment of similar services in any manner.
            </p>
            <p class="py-2">
                11.2.6. placing on the Site or Service, any misleading, defamatory, obscene, offensive or indecent material
                or material which breaches any intellectual property rights of others.
            </p>
            <p class="py-2">
                11.2.7. reverse engineering, disassembling, decompiling, or translating any software or other components of
                the Site, System or Services;
            </p>
            <p class="py-2">
                11.2.8. any copyright violation of the Site or System;
            </p>
            <p class="py-2">
                11.2.9. transmitting any information (including job posts, messages and hyperlinks) on or through the Site
                that is disruptive or competitive to the provision of HCP Services by the Company;
            </p>
            <p class="py-2">
                11.2.10. making any unsolicited communications to other HCPs;
            </p>
            <p class="py-2">
                11.2.11. framing or hot linking or deep linking any the Company Content.
            </p>
            <p class="py-2">
                11.3. HCP agrees not to use any device, software or routine to interfere or attempt to interfere with the
                proper working of the Site or System, or any transaction being conducted on the Site or System, or with any
                other person's use of the Site or System.
            </p>
            <p class="py-2">
                11.4. The Company reserves and shall have all the rights to take necessary actions, at its sole discretion,
                including claiming damages that may occur due to HCP's above acts or involvement/participation in any way on
                its own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of
                Services).
            </p>
            <p>12. <span class="HcpSubHeading">DATA RETENTION</span></p>
            <p class="py-2">
                12.1. HCP agrees that it is HCP's responsibility to ensure and keep a copy of all data pertaining to the HCP
                account in the HCP's own computer or any other place or in any other form.
            </p>
            <p class="py-2">
                12.2. HCP hereby declares that the HCP understands and agrees that the Company is not obliged to keep or
                preserve data of the HCP, and there may be hardware, System or network failure of the Site, including data
                storage facility of the Company, which may result in partial or total loss of data, and in case of such loss
                of data, the Company shall not be obliged or held responsible to retrieve or restore or provide a copy of
                the data to the HCP, with or without cost; and further the Company shall not be held responsible for any
                payment or compensation or damages to the HCP, for any such data loss or failure of the Company to retrieve
                or restore or provide a copy of the data to the HCP, and it shall be the sole and exclusive responsibility
                of the HCP to keep a copy of the data, with respect to anything related to the HCP Account.
            </p>
            <p>13. <span class="HcpSubHeading">INFORMATION UPLOADED BY THE HCP ON THE SITE</span></p>
            <p class="py-2">
                13.1. While the Company takes utmost care with respect to the security of the information you decide to
                upload, you understand that any information that you disclose on the Site, is at your own risk. By uploading
                / sharing / disclosing any information on the Site, you hereby give your consent to the Company to store
                such information on the Company's servers.
            </p>
            <p class="py-2">
                13.2. That some information provided by the HCPs on the Site may qualify as "Sensitive personal data or
                information", as defined under The Information Technology (Reasonable Security Practices and Procedures and
                Sensitive Personal Data or Information) Rules, 2011, for which Company has separate Privacy Policy.
            </p>
            <p>14. <span class="HcpSubHeading">COMMUNICATION, SMS MESSAGES, MAILS, CALLS RECEIVED FROM THE SITE:</span></p>
            <p class="py-2">
                14.1. When you use the Site or access any material, information or Services through the Site, you agree and
                understand that you are communicating with us, through electronic medium and you consent to receive
                communications, through any mode of communication from Company, time to time, as and when required by
                Company, for any purpose.
            </p>
            <p class="py-2">
                14.2. By verifying the mobile number at the time of creation of the HCP Account and by registering as a
                Registered HCP on the Site, HCP consents to be contacted by Company via phone, and/or SMS notifications,
                WhatsApp, email or any other utility, application, method or mode of communication, and authorizes the
                Company, to send any information or communication relating to the Services availed by the HCP from the
                Company, newsletter, promotional material, advertisements, or to contact for feedbacks, reviews, etc. or for
                any other purpose, including the communications relating to your registration, transactions that you carry
                out through the Site, reviews, feedbacks, and promotions that are undertaken by the Company. Further, the
                Company may also send notifications and reminders with respect to other HCP Services that you have
                subscribed to on the Site. Please note that while the Company endeavours to provide these notifications and
                reminders to you promptly, the Company does not provide any guarantee and shall not be held liable or
                responsible for the failure to send such notifications or reminders to you. It is HCP's responsibility to
                ensure that you attend any appointments and consultations that you may schedule with an End User through the
                Site.
            </p>
            <p>15. <span class="HcpSubHeading">IPR, TRADEMARK, COPYRIGHT - USE, RESTRICTIONS AND LIMITATIONS</span></p>
            <p class="py-2">
                15.1. The Site is held, controlled and operated by the Company, and all Intellectual Property Rights /IPR
                including copyright in the Site and Service solely and exclusively belongs to and owned by the Company. All
                intellectual property rights in and title to the Site, System and Services, the present or future
                modifications / upgradations thereof and standard enhancements thereto shall remain the property of the
                Company
            </p>
            <p class="py-2">
                15.2. All IPR protected material on the Site, including images, text, illustrations, audio clips,
                trademarks, logos, labels, video clips and HCP Information, are the intellectual property of the Company and
                / or of their respective owner(s). The IPR including the HCP Information that is collected by the Company
                from the End Users and the HCP(s), subject to the privacy policy of the Company, shall be owned and
                controlled by the Company. Any redistribution, modification or reproduction of part or all of the contents
                featured in the Site or Service in any form is prohibited and actionable. You are not permitted to
                distribute or commercially exploit the IPR or the HCP Information. Nothing in these HCP Terms of Use grants
                the HCP any right in respect of Intellectual Property Rights of Company.
            </p>
            <p class="py-2">
                15.3. Subject to the terms of this Agreement, the Company authorizes the HCP to view and access the HCP
                Information on or from the Site solely for ordering, receiving, delivering and communicating only as per
                this Agreement.
            </p>
            <p class="py-2">
                15.4. This Agreement permits you to use the Site only for your practice use only. You must not reproduce,
                distribute, modify, create derivative works of, publicly display, publicly perform, republish, download,
                store or transmit any of the material on our Site except as generally and ordinarily permitted through the
                Site according to these HCP Terms of Use.
            </p>
            <p>16. <span class="HcpSubHeading">FORCE MAJEURE</span></p>
            <p class="py-2">
                16.1. Neither Party shall be liable or deemed in default for failure to fulfill any obligation under this
                Agreement due to causes beyond its reasonable control. Such causes or conditions shall include, but shall
                not be limited to, acts of God or of the public enemy, acts of the Government in either its sovereign or
                contractual capacity, fires, floods, epidemics, quarantine restrictions, strikes, shortages of labour or
                materials, freight embargoes, unusually severe weather, electrical power failures, telecommunication or
                internet backbone outages, failure of an internet access provider or other similar causes beyond the
                Parties' control, and neither Party shall be liable for losses, expenses or damages, ordinary, special,
                remote or consequential, resulting directly or indirectly from such causes.
            </p>
            <p>17. <span class="HcpSubHeading">TERMINATION</span></p>
            <p class="py-2">
                17.1. HCP may deactivate the HCP Account and end the registration at any time, for any reason, by writing to
                us through the Contact Us form on the Site. The Company may suspend or terminate your use of the Site, your
                Account and/or registration for any reason at any time, at its sole discretion. The Company reserves the
                right to maintain, delete or destroy all communications and materials posted or uploaded to the Site
                pursuant to its internal record retention and/or content destruction policies.
            </p>
            <p>18. <span class="HcpSubHeading">RIGHTS AND OBLIGATIONS RELATING TO CONTENT</span></p>
            <p class="py-2">
                18.1. As mandated by Regulation 3(2) of the Information Technology (Intermediaries guidelines) Rules, 2011
                <a href="http://deity.gov.in/sites/upload_files/dit/files/GSR314E_10511(1).pdf" target="_blank">(IG Rules)</a>, the Company hereby
                informs HCPs that they are not permitted to host, display, upload, modify, publish, transmit, update or
                share any information that:
            </p>
            <p class="py-2">
                18.1.1. belongs to another person and to which the HCP does not have any right to;
            </p>
            <p class="py-2">
                18.1.2. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic,
                libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging,
                relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
            </p>
            <p class="py-2">
                18.1.3. harm minors in any way;
            </p>
            <p class="py-2">
                18.1.4. infringes any patent, trademark, copyright or other proprietary rights;
            </p>
            <p class="py-2">
                18.1.5. violates any law for the time being in force;
            </p>
            <p class="py-2">
                18.1.6. deceives or misleads the addressee about the origin of such messages or communicates any information
                which is grossly offensive or menacing in nature;
            </p>
            <p class="py-2">
                18.1.7. impersonate another person;
            </p>
            <p class="py-2">
                18.1.8. contains software viruses or any other computer code, files or programs designed to interrupt,
                destroy or limit the functionality of any computer resource;
            </p>
            <p class="py-2">
                18.1.9. threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
                foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents
                investigation of any offence or is insulting any other nation.
            </p>
            <p class="py-2">
                18.1.10. the Company, upon obtaining knowledge by itself or any third party of any of the above acts by a
                HCP, then it shall be entitled to remove or disable access to the material or information that is in
                contravention of this Agreement and to immediately terminate the access or usage rights of the HCP to the
                Site.
            </p>
            <p class="py-2">
                18.2. The Company may disclose or transfer HCP-generated information to its affiliates or governmental
                authorities in such manner as permitted or required by applicable law, and you hereby consent to such
                transfer. The Company will comply with any duly-issued government or court directions to disable access to
                the HCP-generated information, should it be found to be illegal by a competent governmental authority.
            </p>
            <p>19. <span class="HcpSubHeading">INDEMNIFICATION</span></p>
            <p class="py-2">
                19.1.HCP undertakes to indemnify, defend and hold harmless the Company and its officers, directors,
                partners, owners, administrator, independent contractors, subsidiaries, licensors, suppliers, employees,
                agents and affiliates for and from any loss, claim, actions, demands, liabilities and settlements, including
                lawyer's fees, fees of third parties, etc., ("Claims"), by reason of, in any way relating to, or arising out
                of HCP's violation of these terms and conditions, or any conduct of the HCP or any other person operating
                for and on behalf of the HCP. The HCP further undertakes to indemnify & hold harmless, the Company against
                any judgment, proceedings, liability or cost resulting from or arising out of Use of the Site or
                information/data provided on the Site or Services provided by the Company, to the HCP.
            </p>
            <p class="py-2">
                19.2. HCP hereby undertakes to indemnify the Company for all losses, damage, costs, charges and expenses
                incurred as a result of HCP's misrepresentations or fraudulent feedback that has adversely affected the
                Company or its HCPs, or has caused any direct or indirect loss to the Company.
            </p>
            <p>20. <span class="HcpSubHeading">GEOGRAPHICAL RESTRICTIONS</span></p>
            <p class="py-2">
                20.1 The Company makes no representation that all products, HCP Services and/or material described on the
                Site, or the HCP Services available through the Site, are appropriate or available for use in locations in
                all states and territories within India.
            </p>
            <p>21. <span class="HcpSubHeading">LIMITATION OF LIABILITY</span></p>
            <p class="py-2">
                21.1. This paragraph shall apply to all content and functionality of the Company, Site, System, Services and
                HCP Services.
            </p>
            <p class="py-2">
                21.2. Under no circumstances, including on account of any act, omission, commission or negligence, shall the
                Company including or anyone else involved in creating, producing or distributing the Services be liable for
                any direct, indirect, incidental, special or consequential damages that result from the use of or inability
                to use the Site, Services, HCP Services or that results from mistakes, omissions, interruptions, deletion of
                files, errors, defects, delays in operation, or transmission or any failure of performance, whether or not
                limited to acts of God, communication failure, theft, destruction or unauthorized access to the Company
                records, programs or the Site, Services, or HCP Services.
            </p>
            <p class="py-2">
                21.3. Notwithstanding the above, the HCP's exclusive remedies for all damages, losses and causes of actions
                whether in contract, including negligence or otherwise, shall not exceed the aggregate amount, which the
                Company has received from the HCP for the service in question. Such limitations shall apply to the Company's
                total liability, including without limitation any liability for damages caused or allegedly caused by any
                failure of performance, error, omission, interruption, deletion, defect, delay in operation of transmission,
                communications failure, theft of destruction of or unauthorized access to, alteration of, or use of records,
                whether for breach of contract, tortuous behaviour, negligence, or under any other cause of action.
            </p>
            <p class="py-2">
                21.4. In no event shall the Company be liable for any direct damages in excess of the purchase price paid by
                the HCP for the Service(s). In addition, to the maximum extent permitted by law, in no event shall the
                Company be liable for any special, punitive, indirect, incidental or consequential damages, including but
                not limited to personal injury, wrongful death, loss of use, loss of profits, interruption of service or
                loss of data, whether in any action in warranty, contract, tort (including, but not limited to negligence or
                fundamental breach), or otherwise arising out of or in any way connected with the use of, or the inability
                to use, this Site or any Service offered through this Site or any material or information contained in,
                accessed through, or products purchased from the Site, even if an authorized representative of the Company
                is advised of the likelihood or possibility of the same.
            </p>
            <p class="py-2">
                21.5. The Company shall not be liable for any breach of service or service deficiency by any HCP.
            </p>
            <p class="py-2">
                21.6. The Company shall not be liable for any damages or injury caused by any failure of performance, error,
                omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication
                line failure, theft or destruction or unauthorized access to, alteration of, or use of record, in any way
                relating to or arising out of the use of the Site or HCP Services.
            </p>
            <p class="py-2">
                21.7. The Company has no liability or responsibility, in case of any dispute or difference that may arise,
                with respect to the payment made by the HCP or the misuse of the information and details, inserted by the
                HCP at the time of making payment.
            </p>
            <p class="py-2">
                21.8. Without prejudice to the generality of the above, the Company will not be liable (directly or
                vicariously) for:
            </p>
            <p class="py-2">
                21.8.1. any wrong medication or treatment quality being given by the HCP(s), or any medical negligence on
                part of the HCP(s);
            </p>
            <p class="py-2">
                21.8.2. any misconduct or inappropriate behaviour by the HCP or the HCP's staff;
            </p>
            <p class="py-2">
                21.8.3. cancellation or rescheduling of booked appointment or any variance in the fees charged;
            </p>
            <p class="py-2">
                21.8.4. Further, the Company shall not be liable, under any event, for any comments or feedback given by any
                of the HCPs in relation to the HCP Services provided by another HCP. All such feedback should be made in
                accordance with applicable law. The option of HCPs to give feedback remains at the Company's sole discretion
                and may be modified or withdrawn at its sole discretion. The Company may moderate such feedback at any time.
                The Company shall not be obliged to act in any manner to give effect to the content of HCPs' feedback, such
                as suggestions for delisting of a particular HCP from the Site.
            </p>
            <p>22. <span class="HcpSubHeading">THIRD PARTY ADVERTISEMENTS</span></p>
            <p class="py-2">
                22.1. The Company reserves the rights to display sponsored advertisements or third party advertisements on
                the Site "Sponsored Listings". Without prejudice to the status of other content, the Company will not be
                liable for the accuracy of information or the claims made in the Sponsored Listings. The Company does not
                require or encourage HCPs to visit any Sponsored Listings page or to avail any HCP Services from them. The
                Company is not be liable for the HCP Services of the providers of the Sponsored Listings. Your dealings
                with, or participation in promotions of, advertisers other than Company found on or through the Site,
                including payment and delivery of related goods or services, and any other terms, conditions, warranties or
                representations associated with such dealings, are solely between you and such advertiser. The Company shall
                not be responsible or liable for any claim, error, omission, inaccuracy in advertising material or any loss
                or damage of any sort incurred as the result of any such dealings or as the result of the presence of such
                advertisers on the Site.
            </p>
            <p class="py-2">
                22.2 Further, the Company shall not be responsible nor liable for any consequential damages arising on
                account of you relying on the contents of these advertisements.
            </p>
            <p>23. <span class="HcpSubHeading">APPLICABLE LAW AND DISPUTE SETTLEMENT</span></p>
            <p class="py-2">
                23.1. HCP agrees that terms of this Agreement and any contractual relationship arising out of the Agreement
                or use of the Site or Services or HCP Services, shall be governed by and construed solely and exclusively in
                accordance with the Indian laws.
            </p>
            <p class="py-2">
                23.2. The HCP hereby agrees that any action at law or in equity, arising out of or relating to these Terms
                and Conditions, including but not limited to arbitration or any issue arising out of the arbitration
                proceedings, appointment of the arbitrator, grant of injunction, which is arising from this Agreement, shall
                be filed only in the Courts at Mumbai.
            </p>
            <p class="py-2">
                23.3. The HCP hereby consent and submit to the exclusive jurisdiction of Courts of competent jurisdiction at
                Chennai, India which shall be to the exclusion of all other courts in India or any other country.
            </p>
            <p class="py-2">
                23.4. Any dispute, claim or controversy arising out of or relating to this Agreement, including the
                determination of the scope or applicability of this Agreement to arbitrate, or your use of the Site or the
                HCP Services or information to which it gives access, shall be determined by Arbitration, before a sole
                arbitrator appointed by the Company.
            </p>
            <p class="py-2">
                23.5. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996.
            </p>
            <p class="py-2">
                23.6. The seat and venue of such arbitration shall be at Mumbai.
            </p>
            <p class="py-2">
                23.7. All proceedings of such arbitration, including, without limitation, any awards, shall be in the
                English language.
            </p>
            <p class="py-2">
                23.8. The award shall be final and binding on the parties to the dispute.
            </p>
            <p>24. <span class="HcpSubHeading">SEVERABILITY</span></p>
            <p class="py-2">
                24.1. If any or part of this Agreement is declared invalid, unlawful or unenforceable, then such part will
                be severed. The remaining parts of this Agreement will continue to be valid and enforceable to the fullest
                extent permitted by applicable laws and regulations.
            </p>
            <p>25. <span class="HcpSubHeading">WAIVER</span></p>
            <p class="py-2">
                25.1 Any waiver by the Company of any breach of any term or condition of the Agreement by the HCP, shall not
                preclude the Company from enforcing any subsequent breach of that or any other term or condition and shall
                not be considered as a waiver of the subsequent breach also.
            </p>
            <p>26. <span class="HcpSubHeading">SYSTEM REQUIREMENTS</span></p>
            <p class="py-2">
                26.1. For accessing the Site and the Services offered by the Company, the HCP shall be solely and
                exclusively responsible to determine the appropriateness and requirements of the system, hardware and
                software, including an appropriate multimedia mobile or any other device or computer with camera, speaker,
                microphone and scanner, etc., The Company does not warrant suitability of any system including hardware.
                However, the Company may come-up with non-mandatory advice about the System requirements, which the HCP may
                accept or reject at his own discretion.
            </p>
            <p class="py-2">
                26.2. If you use these HCP Services, you consent to us and our Affiliates' and licensees' transmission,
                collection, maintenance, processing and use of your location data and queries to provide and improve
                location-based products and HCP Services. You may withdraw this consent at any time by turning off the
                location HCP Services settings on your device. Such location Services and the use of the HCP devices through
                the Internet and the telephony may involve cost for which the HCP shall directly connect with the Internet
                or Telecom Service Provider.
            </p>
            <p>27. <span class="HcpSubHeading">ASSIGNMENT</span></p>
            <p class="py-2">
                27.1. You hereby agree that the Company may assign this Agreement along with the Company, Site and Services
                at any time at its sole discretion, without the requirement of any consent from the HCP and the HCP also
                waives the right of any such requirement of consent, to any parent, subsidiary or affiliated company, or as
                part of the sale to, merger with, or other transfer or license of any kind, of the Company to another
                entity(s); and any such assignee or transferee or licensee shall automatically step in to the shoes of the
                Company for the purpose of this Agreement.
            </p>
            <p class="py-2">
                27.2. The HCP shall not be entitled to assign, transfer or sublicense these HCP Terms of Use to anyone else
                and any attempt to do so in violation of this Agreement, and the same shall be null and void.
            </p>
            <p class="py-2">
                HCP UNDERSTANDS AND ACKNOWLEDGES THAT BY CHECKING THE "AGREE/ I AGREE" TAB/BUTTON ON THE SITE, AT THE TIME
                OF REGISTRATION, FOR THIS AGREEMENT AND/OR ANY OTHER FORM(S) OR AGREEMENT PRESENTED TO YOU ON THE SITE, OR
                BY USING ANY SERVICES, YOU ARE AGREEING TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND THAT SUCH ACTION
                / CONFIRMATION CONSTITUTES A LEGAL SIGNATURE RESULTING IN A BINDING CONTRACT BETWEEN YOU AND THE COMPANY.
            </p>

        </div>
    </div>
</template>
<script>
export default ({
  components: {
  },
  data() {
    return {}
  }
})
</script>
<style>
.hcptermsCondtionMainDiv {
    text-align: justify;
}

.hcpTermConditionHeading {
    font-size: 22px;
    color: black;
    font-weight: 550;
    margin-top: 5rem;
}

.HcpSubHeading {
    font-size: 19px;
    color: black;
    font-weight: 550;
}
@media screen and (max-width:991px) {
    .hcpTermConditionHeading {
        margin-top: 2rem;
    }
}
</style>